<template>
  <ModalMainNew
    show-close-mobile
    secondary-background-color
    modal-width-mode="sm"
    @close="onClose"
  >
    <div class="pl-10 w-full mx-auto pb-11 relative top-0">
      <article class="animate-slide-down-fade-in01s">
        <div class="header mb-5">
          <div class="flex items-center">
            <SvgIcon
              :icon="tradingOrderDetailModalData.data.assetCode"
              height="20"
              width="20"
              class="icon inline-block rounded"
            />
            <SvgIcon
              width="16"
              height="16"
              class="ml-1"
              icon="caret-up-fill"
              :class="{
                'text-green': tradingOrderDetailModalData.data.isLong,
                'text-red': !tradingOrderDetailModalData.data.isLong,
                'rotate-180': !tradingOrderDetailModalData.data.isLong
              }"
            />
            <div class="text-xs text-slate-100 font-semibold ml-1 uppercase">{{ tradingOrderDetailModalData.display.exitTime }}</div>
          </div>
        </div>
        <div class="body flex items-center flex-wrap w-full mx-auto">
          <div class="w-1/2 overflow-hidden text-ellipsis whitespace-nowrap">
            <div class="logo flex items-center">
              <SvgIcon
                width="120"
                class="inline-block transition-all mt-1"
                icon="metawin-logo-white"
              />
            </div>
          </div>
          <div class="w-1/2 overflow-hidden text-ellipsis whitespace-nowrap">
            <div class="text-slate-100 text-xs font-bold uppercase"/>
            <div class="user">
              <div class="overflow-hidden">
                <div class="flex items-center group mr-1 pt-1">
                  <UserAvatar
                    :url="tradingOrderDetailModalData.user.avatarUrl"
                    class="mr-2 rounded-full border-transparent"
                    size="xs"
                  />
                  <span class="whitespace-nowrap text-ellipsis overflow-hidden text-xs sm:text-sm font-bold pr-8">
                    {{ tradingOrderDetailModalData.user.displayName }}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div class="w-1/2 overflow-hidden text-ellipsis whitespace-nowrap pt-6">
            <div class="text-slate-100 text-xs font-bold uppercase">Entry Price</div>
            <div class="text-sm text-white tabular-nums">
              {{ tradingOrderDetailModalData.display.entryPrice }}
            </div>
          </div>
          <div class="w-1/2 overflow-hidden text-ellipsis whitespace-nowrap pt-6">
            <div class="text-slate-100 text-xs font-bold uppercase">Bust Price</div>
            <div class="text-sm text-white tabular-nums">
              {{ tradingOrderDetailModalData.display.bustPrice }}
            </div>
          </div>
          <div class="w-1/2 overflow-hidden text-ellipsis whitespace-nowrap pt-4">
            <div class="text-slate-100 text-xs font-bold uppercase">Wager</div>
            <div class="text-sm text-white tabular-nums">
              {{ tradingOrderDetailModalData.display.stakeBaseAmount }}
            </div>
          </div>
          <div class="w-1/2 overflow-hidden text-ellipsis whitespace-nowrap pt-4">
            <div class="text-slate-100 text-xs font-bold uppercase">Profit & Loss</div>
            <div class="text-sm text-white">
              <span
                class="flex"
                :class="toPriceChangeClass(tradingOrderDetailModalData.data.roi)"
              >
                <span class="tabular-nums">{{ tradingOrderDetailModalData.display.profitBaseAmount }}</span>
                <tippy placement="bottom" trigger="mouseenter click">
                  <span class="icon-ico-info ml-1 text-base/[21px] text-slate-100 hover:text-white cursor-pointer"/>
                  <template #content>
                    <p class="mb-3 text-sm">A variable fee was charged as a fraction of profits upon closing the trade.</p>
                    <p class="text-sm">Check the ROI calculator for more details.</p>
                  </template>
                </tippy>
              </span>
            </div>
          </div>
          <div class="w-1/2 overflow-hidden text-ellipsis whitespace-nowrap pt-4">
            <div class="text-slate-100 text-xs font-bold uppercase">Multiplier</div>
            <div class="text-sm text-white">
              {{ tradingOrderDetailModalData.display.leverage }}
            </div>
          </div>
          <div class="w-1/2 overflow-hidden text-ellipsis whitespace-nowrap pt-4">
            <div class="text-slate-100 text-xs font-bold uppercase">ROI</div>
            <div
              class="text-sm tabular-nums"
              :class="toPriceChangeClass(tradingOrderDetailModalData.data.roi)"
            >
              {{ tradingOrderDetailModalData.display.roi }}
            </div>
          </div>
          <div class="w-1/2 overflow-hidden text-ellipsis whitespace-nowrap pt-4">
            <div class="text-slate-100 text-xs font-bold uppercase">Exit Price</div>
            <div class="text-sm text-white tabular-nums">
              {{ tradingOrderDetailModalData.display.exitPrice }}
            </div>
          </div>
          <div class="w-1/2 overflow-hidden text-ellipsis whitespace-nowrap pt-4">
            <div class="text-slate-100 text-xs font-bold uppercase">Status</div>
            <div
              class="text-sm"
              :class="{
                'text-red': tradingOrderDetailModalData.display.status === 'Busted'
              }"
            >
              {{ tradingOrderDetailModalData.display.status }}
            </div>
          </div>
        </div>
      </article>
    </div>
  </ModalMainNew>
</template>

<script setup>
import { storeToRefs } from 'pinia';
import { useUiStore } from '@/store/ui';
import { useTrading } from '@/composables/useTrading';

const uiStore = useUiStore();

const {
  showTradingOrderDetailModal,
  tradingOrderDetailModalData,
} = storeToRefs(uiStore);

const {
  toPriceChangeClass,
} = useTrading();

function onClose() {
  showTradingOrderDetailModal.value = false;
  tradingOrderDetailModalData.value = null;
}
</script>
